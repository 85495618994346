
import { defineComponent } from 'vue';
import {Button, Table, Searchbar} from '@/ui/';
import { Workstation } from '@/model';
import { WorkstationController } from '@/controller/';
import store from "@/store";

export default defineComponent({
    name: "WorkstationList",
    components: { Button, Searchbar, Table },
    created(){
        this.handleGetWorkstations();
    },
    data(){
        return{
            store,
            workstationsTableData:{
                thFields: [ {title:this.$t("label.title"), property: "title", type: "string"}, {title: this.$t("label.description"), property: "description", type: "string"}, {title: this.$t("label.defaultHourlyRate"), property: "defaultHourlyRate", type: "string"} ],
                tbActions: [ { name: "editWorkstation", icon: store.getters.svgIcons.pencil }, { name: "confirmDelete", icon: store.getters.svgIcons.remove } ]
            },
            workstations: [] as Array<Workstation>,
            searchInput: ""
        }
    },
    computed: {
        filteredWorkstations() {
            let filtered = [] as Array<any>;
            if (this.searchInput) {
                this.workstations.forEach((workstation : any) => {
                    JSON.stringify(workstation.title).toLowerCase().includes(this.searchInput.toLowerCase()) && filtered.push(workstation);
                    !filtered.includes(workstation) && workstation.description && JSON.stringify(workstation.description).toLowerCase().includes(this.searchInput.toLowerCase()) && filtered.push(workstation);
                })
            } else {
                filtered = this.workstations;
            }
            return filtered;
        }
    },
    methods:{
        async handleGetWorkstations() {
            const res = await WorkstationController.fetchWorkstations();
            if(!res.error){
                this.workstations = res.items;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        handleTableActionClicked(action : string, item:any) {
            switch(action) {
                /*case "openDetail":
                    this.handleOpenDetailView(item.uid);
                    break*/
                case "confirmDelete":
                    this.handleConfirmDelete(item);
                    break
                case "editWorkstation":
                    this.handleEdit(item.uid);
                    break
            }
        },
        handleShowCreateForm() {
            this.$router.push("/administration/workstation/form/0");
        },
        handleEdit(uid:number) {
            this.$router.push("/administration/workstation/form/" + uid);
        },
        handleConfirmDelete(item : any) {
            this.$confirm(this.$t("confirm.delete"), this.$t('confirm.deleteWorkstationText', { name: item.title }), {
                labelConfirm: this.$t("button.delete"),
                onConfirm: () => { this.handleDelete(item.uid) },
                //onCancel: () => { console.log("onCancel"); }
           });
        },
        async handleDelete(uid : number) {
            const res = await WorkstationController.deleteWorkstation(uid);
            if (!res.error) {
                this.workstations = this.workstations.filter(function(item : any) {
                   return item.uid != res.workstation.uid;
                });
                this.$notify(this.$t("success.workareaDeleted"), { position: "top" });
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        }
    }
})
